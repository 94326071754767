import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		dialogButton: {
			textTransform: 'none',
			padding: 16,
			borderRadius: 6,
			margin: 'auto'
		},
		filterButtonContainer: {
			position: 'fixed',
			display: 'flex',
			width: '100%',
			justifyContent: 'center',
			bottom: 24,
			zIndex: 1
		},
		filterButton: {
			color: theme.palette.common.black,
			textTransform: 'none',
			height: 48,
			backgroundColor: theme.palette.common.white,
			...theme.border({ radius: 24, width: 2, color: theme.palette.line['100'] }),
			'&:hover': {
				backgroundColor: theme.palette.common.white,
				borderColor: `${theme.palette.common.black}5f`
			}
		},
		filterButtonBadge: {
			'& > div:first-child': {
				height: 24,
				width: 24,
				lineHeight: '24px',
				borderRadius: 12,
				fontSize: 14,
				fontFamily: 'Poppins',
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.common.white
			}
		},
		filterButtonStartIcon: {
			color: theme.palette.secondary.main
		},
		dialogPaper: {
			width: '100%',
			maxWidth: 'unset',
			maxHeight: 'unset',
			height: `calc(100% - ${theme.spacing(5)}px)`,
			margin: theme.spacing(5, 0, 0),
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			'& > div': {
				display: 'flex',
				flexDirection: 'column'
			}
		},
		dialogTitleContainer: {
			margin: 'auto'
		},
		title: {
			marginBottom: theme.spacing(1)
		},
		dialogContent: {
			height: 'unset',
			flex: 1,
			paddingTop: 0
		},
		closeIcon: {
			right: 0,
			position: 'absolute'
		},
		header: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(5)
		},
		filtersAndSortingHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: theme.spacing(2),
			alignItems: 'center'
		},
		filter: {
			width: '100%',
			marginTop: theme.spacing(3),
			[theme.breakpoints.up('sm')]: {
				marginRight: theme.spacing(3),
				width: 'unset'
			},
			'&:last-child': {
				[theme.breakpoints.up('sm')]: {
					marginBottom: 0,
					marginLeft: 'auto',
					marginRight: 0
				}
			}
		},
		filtersHeader: {
			display: 'flex',
			alignItems: 'center',
			flexWrap: 'wrap',
			[theme.breakpoints.up('sm')]: {
				flexWrap: 'nowrap'
			}
		},
		topicDropdown: {
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				width: 'unset'
			}
		},
		topicWideDropdown: {
			[theme.breakpoints.up('sm')]: {
				minWidth: 300
			}
		},
		filterDropdown: {
			minWidth: 200,
			width: '100%',
			margin: theme.spacing(2, 2, 0, 0)
		},
		minimalDropdown: {
			paddingRight: 0
		},
		switchContainer: {
			flexWrap: 'wrap',
			margin: theme.spacing(-1.5, -1.5, 0, 0)
		},
		divider: {
			margin: theme.spacing(2, -3)
		},
		switchOption: {
			flexGrow: 'unset',
			flexBasis: 'unset',
			...theme.border({ color: theme.palette.line.main, radius: 6 }),
			margin: theme.spacing(1.5, 1.5, 0, 0),
			padding: `6px ${theme.spacing(1.5)}px`,
			'&:hover': {
				'& > p': {
					'&::after': {
						display: 'none'
					}
				},
				...theme.border({ color: theme.palette.secondary.main, radius: 6 })
			},
			'& > p': {
				lineHeight: 'unset',
				height: 'unset',
				fontWeight: 400
			}
		},
		switchSelected: {
			backgroundColor: theme.palette.secondary['100'],
			...theme.border({ color: theme.palette.secondary.main, radius: 6 }),
			'& > p': {
				color: theme.palette.secondary.main,
				'&::after': {
					display: 'none'
				}
			}
		},
		sectionsMenuContainer: {
			height: 36
		},
		sectionsMenuItem: {
			height: 32,
			display: 'flex',
			paddingTop: 0,
			paddingBottom: 0,
			justifyContent: 'center',
			'& > p': {
				lineHeight: '32px'
			}
		},
		languageDropdown: {
			marginTop: theme.spacing(3)
		},
		languageDropdownIcon: {
			marginRight: theme.spacing(0.5)
		}
	}),
	// dropdown
	{ name: 'Filters', index: 100 }
);

export default useStyles;
